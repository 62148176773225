import { State } from "@/store";
import { Store } from "vuex";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IApiKey, IContactUsRequest, ISubscribeConfirmRequest, ISignUp_CustomerRequest, Toast } from "@/types";

axios.defaults.validateStatus = () => {
  return true;
};

export enum Status {
  OK = 200,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  CONFLICT = 409,
}

export class Api {
  private static BASE_URL = process.env.VUE_APP_USER_API_BASE_URL;

  public static async contactUs(store: Store<State>, data: IContactUsRequest): Promise<AxiosResponse> {
    return await axios.put(`${this.BASE_URL}/contact-us`, data, this.defaultConfig(store));
  }

  public static async deleteApiKey(store: Store<State>, id: string): Promise<AxiosResponse> {
    return await axios.delete(`${this.BASE_URL}/api-key?apiKeyId=${id}`, this.defaultConfig(store));
  }

  public static async getApiKeys(store: Store<State>): Promise<AxiosResponse<IApiKey[]>> {
    return await axios.get<IApiKey[]>(`${this.BASE_URL}/api-key`, this.defaultConfig(store));
  }

  public static async putApiKey(store: Store<State>): Promise<AxiosResponse<IApiKey>> {
    return await axios.put<IApiKey>(`${this.BASE_URL}/api-key`, undefined, this.defaultConfig(store));
  }

  public static async subscribeConfirm(store: Store<State>, data: ISubscribeConfirmRequest): Promise<AxiosResponse> {
    return await axios.patch(`${this.BASE_URL}/subscribe-confirm`, data, this.defaultConfig(store));
  }
  public static async webuserVerify(store: Store<State>, name: string, data: ISignUp_CustomerRequest): Promise<AxiosResponse> {
    const dataOut: any = data;
    dataOut.name = name;
    return await axios.patch(`${this.BASE_URL}/webuser-verified`, dataOut, this.defaultConfig(store));
  }

  public static checkStatus(store: Store<State>, statusCode: number, defaultMessage: string): boolean {
    switch (statusCode) {
      case Status.OK:
        return true;
      case Status.UNAUTHORIZED:
        store.dispatch("signOut");
        return false;
      case Status.NOT_FOUND:
      case Status.CONFLICT:
      default:
        Toast.error(store, defaultMessage);
        return false;
    }
  }

  private static defaultConfig(store: Store<State>): AxiosRequestConfig {
    return {
      headers: {
        Authorization: store.state.session.getIdToken().getJwtToken(),
      },
    };
  }
}
